
import React from 'react';
import './List.scss';

/**
 * Item used to iterate through a list of components
 */
const Item = ({
    country = "ca",
    href = "href",
    label = "label",
    children = [],
    alt = ""
}) => (
    <div className="c-list-name">
        <img className="c-list-flag" alt={ alt } src={ `/img/flags/${ country }.png` } />
        <span>{ children }</span>
        <a target="_blank" rel="noopener noreferrer" href={ href }>
            { label } <img src="/img/icons/link-arrow.svg" alt={ label } />
        </a>
    </div>
)

/**
 * Product descriptor is used for displaying basic product information.
 */
const ListComponent = ({ panelId = "list", children }) => {
    const regions = React.Children.toArray(children).filter(({ props }) => {
        return props.originalType === 'ul';
    });
    return (
        <div id={ panelId } className="c-list">
            {
                regions.map(({ props: { title, children } }, index) => (
                    <div key={ index } className="c-list-region">
                        <div className="c-list-region-name">{ title }</div>
                        <div className="c-list-items">
                            {
                                React.Children.map(children, ({ props, index}) => (
                                    <Item key={ index } { ...props } />
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default ListComponent;
