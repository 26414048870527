
import React from 'react';
import './ColumnPanel.scss';

/**
 * Double panel component renders two separating panels. The right side for
 * copy and left side for an image.
 */
const ColumnPanel = ({
    panelId = "panelId",
    title = "title",
    children = []
}) => (
    <section id={ panelId } className="c-columnpan">
        <div className="c-columnpan-top">
            <img src="/img/icons/snake.svg" alt="Copperhead" />
            { title }
        </div>
        <div className="c-columnpan-options">
            {
                React.Children.map(children, ({ props: {
                    title = "title",
                    label = "label",
                    href = "href",
                    titleWordWrap = false,
                    children = []
                } }, index) => (
                    <div key={ index } className="c-columnpan-option">
                        <div className="c-columnpan-number">0{ index + 1 }</div>
                        <div className={
                            `c-columnpan-option-title ${ titleWordWrap === true ? 'word-wrap' : '' }`
                        }>{ title }</div>
                        {
                            React.Children.map(children, (el, index) => {
                                if (el.props.originalType === 'ul') {
                                    return <ul key={ index } className="g-list">
                                        { el.props.children }
                                    </ul>
                                }
                                return el;
                            })
                        }
                        <a target="_blank" rel="noopener noreferrer" className="g-button" href={ href }>
                            { label }
                        </a>
                    </div>
                ))
            }
        </div>
    </section>
)

export default ColumnPanel;
