
import React, { useEffect, createRef } from 'react';
import site from '../../config/site';
import './Footer.scss';

/**
 * Footer navigation displays location, social media elements and copyright.
 */
const Footer = () => {
    const footerRef = createRef();
    // Effect defines scrolling action for effecting the header
    useEffect(() => {
        const height = footerRef.current.offsetHeight;
        const mainEl = document.getElementsByTagName('main');
        mainEl[0].style.marginBottom = `${height - 5}px`;
    }, [ footerRef ]);
    return (
        <footer ref={ footerRef } className="g-footer">
            <div className="c-footer-mailinglist">
                <div className="c-footer-mailinglist-inner">
                    <div className="c-footer-mailinglist-left">
                        <strong>Join our mailing list</strong>
                        <p>
                            Copperhead sends out email blasts to announce major company
                            updates, recent CopperheadOS developments, and occasionally
                            highlight important security-related news.
                        </p>
                    </div>
                    <form
                        id="mc-embedded-subscribe-form"
                        action="https://copperhead.us13.list-manage.com/subscribe/post?u=fb6433dd5ac8c32c2536b56c5&amp;id=550a69d86e"
                        method="post"
                        name="mc-embedded-subscribe-form"
                        className="g-box-outline validate"
                        target="_blank"
                        noValidate>
                        <input type="email" aria-label="Email address" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Your email address" />
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                            <input type="text" aria-label="Email Address" name="b_fb6433dd5ac8c32c2536b56c5_550a69d86e" tabIndex="-1" defaultValue="" />
                        </div>
                        <input type="submit" aria-label="Submit" value="Join Mailing List" name="subscribe" id="mc-embedded-subscribe" className="g-button" />
                    </form>
                </div>
            </div>
            <div className="g-footer-content">
                <div className="g-footer-upper">
                    <div className="g-footer-address">
                        { `${ site.address.street }, ${ site.address.city }, ${ site.address.region }, ${ site.address.country }` }
                    </div>
                    <div className="g-footer-logo"><img src="/img/footer-logo.svg" alt="Copperhead" /></div>
                    <div className="g-footer-social">
                        <a rel="noopener noreferrer" href={ `https://www.${ site.social.facebook }` } target="_blank">
                            <div><img src="/img/icons/social/facebook.svg" alt="Facebook" title="Facebook" /></div>
                        </a>
                        <a rel="noopener noreferrer" href={ `https://www.twitter.com/${ site.social.twitter }` } target="_blank">
                            <div><img width="25" src="/img/icons/social/twitter.svg" alt="Twitter" title="Twitter" /></div>
                        </a>
                        <a rel="noopener noreferrer" href={ `https://www.linkedin.com/company/${ site.social.linkedin }` } target="_blank">
                            <div><img width="25" src="/img/icons/social/linkedin.svg" alt="LinkedIn" title="LinkedIn" /></div>
                        </a>
                        <a rel="noopener noreferrer" href={ `https://www.reddit.com/${ site.social.reddit }` } target="_blank">
                            <div><img width="34" src="/img/icons/social/reddit.svg" alt="Reddit" title="Reddit" /></div>
                        </a>
                        <a rel="noopener noreferrer" href={ `${ site.social.youtube }` } target="_blank">
                            <div><img width="55" src="/img/icons/social/youtube.svg" alt="YouTube" title="YouTube" /></div>
                        </a>
                        <a rel="noopener noreferrer" href={ `https://t.me/${ site.social.telegram }` } target="_blank">
                            <div><img width="34" src="/img/icons/social/telegram.svg" alt="Telegram" title="Telegram" /></div>
                        </a>
                    </div>
                </div>
                <div className="g-footer-lower">
                    &copy; Copperhead. CopperheadOS&trade; is a registered trademark of Copperhead Limited.&nbsp;
                    <a href="https://www.termsfeed.com/privacy-policy/d8d1eb516fd4fcc185f8f81cb00bb550">Privacy Policy</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
