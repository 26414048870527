
import React from 'react';
import './StructuredList.scss';

/**
 * Partners page describes how to become a partner and benefits.
 */
const StructuredList = ({ title = "title", children = [] }) => (
    <div className="c-structuredlist g-box-outline">
        <h3>{ title }</h3>
        <ul>
            {
                React.Children.toArray(children).map((el, index) => (
                    <li key={ index }>
                        <span>{ el.props.title }</span>
                        <div>
                            { el.props.children }
                        </div>
                    </li>
                ))
            }
        </ul>
    </div>
);

export default StructuredList;
