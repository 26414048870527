
import React from 'react';
import './Hamburger.scss';

/**
 * Hamburger menu used for mobile responsiveness.
 */
const Hamburger = ({ enabled = false, action }) => {
    return (
        <button
            onClick={ () => {
                action(enabled === true ? false : true);
            } }
            className={
                'c-hamburger hamburger--emphatic ' + (
                    enabled === true ? 'is-active' : ''
                )
            }
            type="button">
            <span className="c-hamburger-box">
                <span className="c-hamburger-inner"></span>
            </span>
        </button>
    );
}

export default Hamburger;
