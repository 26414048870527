
import React from 'react';
import Phone from './Phone';
import './FeatureList.scss';

/**
 * Feature list renders a list of features for a product with icons or images
 * representing the feature being described.
 */
const FeatureList = ({ panelId, title, info, children }) => {
    const ul = React.Children.toArray(children).filter(({ props }) => {
        return props.originalType === 'ul';
    });
    const divs = React.Children.toArray(children).filter(({ props }) => {
        return props.originalType === 'div';
    });
    return (
        <section id={ panelId } className="c-features" title="Software and secure Android solutions">
            <h2>{ title }</h2>
            <div className="c-features-info">{ info }</div>
            <div className="c-features-features">
                {
                    ul[0].props.children.map(({
                        props: { children, image, href }
                    }, index) => (
                        <div
                            key={ index }
                            className="c-features-feature"
                            style={{ backgroundImage: `url("${ image }")` }}>
                            <p>{ children }</p>
                        </div>
                    ))
                }
            </div>
            <Phone />
            {
                divs.map(({ props: { title, href, buttonLabel, children }}, index) => (
                    <div key={ index } className="c-features-details">
                        <h4>{ title }</h4>
                        <p>{ children }</p>
                        <a className="g-button" href={ href }>
                            { buttonLabel }
                        </a>
                    </div>
                ))
            }
        </section>
    )
};

export default FeatureList;
