
import React from 'react';
import './Clients.scss';

/**
 * Displays a grid of client logos, supports n number as it fills in rows.
 */
const Clients = ({ title = null, children = [] }, index) => (
    <section className={ `c-clients column-${ children.length % 4 }` } title={ title }>
        { title != null && <div className="c-clients-title">{ title }</div> }
        <div className="c-clients-inner">
            {
                React.Children.map(
                    children,
                    ({ props: { href, image, children = "", alt="Copperhead's trusted clients" } }) => (
                        <div className="c-clients-logo" key={ index }>
                            <a target="_blank" rel="noopener noreferrer" href={ href }>
                                <img src={ image } alt={ alt !== "Copperhead's trusted clients" ? alt : children } />
                            </a>
                        </div>
                    )
                )
            }
        </div>
    </section>
)

export default  Clients;
