
import React, { useEffect, useCallback, useRef, useState } from 'react';
import InViewMonitor from 'react-inview-monitor';
import './Phone.scss';


// Custom hook defines the main elements of the header effect
function usePhoneOptions() {
    // Define main references for component
    const areaRef = useRef(null);
    const screenRef = useRef(null);
    const letters = Array(256).join(1).split('');
    let [ interval, setIntervalValue ] = useState(null);
    // Actually triggers a draw function on the phone
    const draw = useCallback(() => {
        if (! screenRef.current) { return; }
        screenRef.current.getContext('2d').fillStyle = 'rgba(0, 0, 0, .05)';
        screenRef.current.getContext('2d').fillRect(
            0, 0, screenRef.current.width, screenRef.current.height
        );
        screenRef.current.getContext('2d').fillStyle = '#63A528';
        letters.map(
            (y_pos, index) => {
                const text = String.fromCharCode(
                    3e4+Math.random() * 33
                );
                const x_pos = index * 10;
                screenRef.current.getContext('2d').fillText(
                    text, x_pos, y_pos
                );
                // Find a random character to render
                letters[index] = (y_pos > 758 + Math.random() * 1e4) ? 0 : y_pos + 10;
                return true;
            }
        );
    }, [ screenRef, letters]);
    // Play the matrix animation
    const play = useCallback(() => {
        setIntervalValue(setInterval(draw, 33));
    }, [ draw, setIntervalValue ]);
    // Stop the matrix animatio
    const stop = useCallback(() => {
        clearInterval(interval);
    }, [ interval ]);
    // Effect triggers defining the phone boundaries and handles start/stops
    useEffect(() => {
        const s = areaRef.current.getBoundingClientRect();
        screenRef.current.width = s.width;
        screenRef.current.height = s.height;
        return function cleanup() {
            stop();
        };
    }, [ screenRef, areaRef, stop ]);
    return [ areaRef, screenRef, play, stop ];
}

/**
 * Phone component renders an animated phone element that looks like
 * the matrix.
 */
const PhoneComponent = () => {
    const [ areaRef, screenRef, play, stop ] = usePhoneOptions();
    return (
        <InViewMonitor
            intoViewMargin="40%"
            onInView={ play }
            onNotInView={ stop }
            repeatOnInView={ true }>
            <figure className="c-phone" ref={ areaRef }>
                <img src="/img/software-phone.png" alt="CopperheadOS secured phone" />
                <canvas aria-label="Scrolling matrix phone" ref={ screenRef }></canvas>
            </figure>
        </InViewMonitor>
    );
}

export default PhoneComponent;
