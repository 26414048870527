
import React, { useState, useEffect, useCallback } from 'react';
import * as Scroll from 'react-scroll';
import { window, document } from 'browser-monads';
import Hamburger from '../components/Hamburger';
import './Header.scss';

// Helper function to return width of browser
function getWidth() {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

// Custom hook defines the main elements of the header effect
function useHeaderOptions(
    expandHeight, collapseHeight, mobileCollapseHeight
) {
    // Define the initial state of all the conditions
    let maxScale = 1;
    if (getWidth() < 875) {
        maxScale = .85;
        collapseHeight = mobileCollapseHeight;
    }
    const Link = Scroll.Link;
    const [ options, setOptions ] = useState({
        alpha: 0,
        height: 150,
        maxScale: maxScale,
        logoScale: maxScale,
        expand: false,
        mobile: false
    });
    // Adjust logo max scale for mobile when there's a window resize
    const handleResize = useCallback(() => {
        if (getWidth() < 875) {
            setOptions(Object.assign({}, options, { maxScale: 1 }));
        } else {
            setOptions(Object.assign({}, options, { maxScale: 1.2 }));
        }
    }, [ setOptions, options ]);
    // On scroll handle adjusting options
    const handleScroll = useCallback(() => {
        const scrollTop = (
            window.scrollY ||
            document.body.scrollTop ||
            document.documentElement.scrollTop
        );
        const percent = Math.min(1, scrollTop / collapseHeight);
        // Update alpha only if it changes
        setOptions((options) => ({
            alpha: percent,
            height: collapseHeight - (percent * expandHeight),
            expand: percent >= 1,
            logoScale: options.maxScale - (0.35 * percent),
            blur: percent * 6,
            maxScale: options.maxScale,
            mobile: false
        }));
    }, [collapseHeight, expandHeight]);
    // Handles the status of the mobile menu
    const toggleMenu = useCallback((enabled) => {
        setOptions(Object.assign({}, options, {
            mobile: enabled
        }));
    }, [ setOptions, options ]);
    // Effect defines scrolling action for effecting the header
    useEffect(() => {
        // Bind window events
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Specify how to clean up after this effect
        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [ handleScroll, handleResize]);
    const navigation = [
        { label: "Android", link: '/android/' },
        { label: "Rebrand", href: '/', anchor: "wardrobe" },
        { label: "Policy", href: '/', anchor: "policy" },
        { label: "Licensing", href: '/', link: "/licensing" },
        { label: "Partners", link: "/partners/" },
        { label: "Blog", href: '/', link: "/blog"},
        { label: "Contact", href: '/', link: "/contact"},
//      { label: "Contact", href: '/', link: "/contact", className: "g-header-navigation-blog" },
        { label: "Get a demo", href: '/tryit/', className: "g-header-navigation-blog"},

    ];
    return [ options, navigation, toggleMenu, Link ];
}

/**
 * Header function used throughout the website.
 */
 export default ({
    expandHeight = 60,
    collapseHeight = 150,
    mobileCollapseHeight = 125
}) => {
    const [ options, navigation, toggleMenu, Link ] = useHeaderOptions(
        expandHeight,
        collapseHeight,
        mobileCollapseHeight
    );
    // Return the structure of the component
    return (
        <header
            className={ `g-header ${options.mobile === true && 'mobile'}` }
            style={{
                backgroundColor: `rgba(0, 0, 0, ${options.alpha - 0.2})`,
                backdropFilter: `blur(${options.blur}px)`,
                boxShadow: `0px 2px 24px 3px rgba(0,0,0,${options.alpha})`
            }}>
            <div className="g-header-inner" style={{ height: options.height }}>
                <div className="g-header-logo">
                    <a href="/" style={{ transform: `scale(${options.logoScale})` }}>
                        <img src="/img/logo.svg" alt="Copperhead" />
                    </a>
                </div>
                <nav className="g-header-navigation">
                    {
                        navigation.map((item, index) => {
                            const className = `g-button ${ item.className ? item.className : '' }`;
                            return (
                                window.location.pathname === '/' && typeof item.anchor !== "undefined" ?
                                <Link
                                    key={ index }
                                    to={ item.anchor }
                                    className={ className }
                                    hashSpy={ true }
                                    smooth={ true }
                                    offset={ -50 }
                                    duration={ 1000 }>
                                    <div className="expander">{ item.label }</div>
                                </Link> :
                                <a key={ index }
                                    className={ className}
                                    href={ item.link ? item.link : item.href + '#' + item.anchor }>
                                    <div className="expander">{ item.label }</div>
                                </a>
                            )
                        })
                    }
                </nav>
                <div className="g-header-right">
                    {
                        // window.location.pathname !== '/contact/' &&
                        <a className="g-button g-header-right-cta no-arrow" href="/tryit/">
                            Get a demo
                        </a>
                    }
                    <Hamburger enabled={ options.mobile } action={ toggleMenu } />
                </div>
            </div>
        </header>
    )
 }
