
import React from 'react';
import './PanelContent.scss';

/**
 * Panel content displays a very basic three column content area.
 */
const PanelContent = ({
    panelId = "panelId",
    title = "title",
    image = "",
    alt = "",
    label = "label",
    href = "/",
    description = "description",
    children = []
}) => {
    const bullets = React.Children.toArray(children).filter(({ props }) => {
        return props.originalType === 'ul';
    });
    return (
        <section id={ panelId } className="c-paneldesc">
            <div className="c-paneldesc-head">
                <img src={ image } alt={ alt === "" ? alt : title } />
            </div>
            <h4>{ title }</h4>
            <p className="c-paneldesc-detail">{ description }</p>
            <div className="c-paneldesc-list">
                {
                    bullets[0].props.children.map(({ props: { children } }, index) => (
                        <div key={ index } className="c-paneldesc-list-item">
                            { children }
                        </div>
                    ))
                }
            </div>
            <a className="g-button with-border c-paneldesc-contact" href={ href }>
                { label }
            </a>
        </section>
    )
}

export default PanelContent;
