
import React from 'react';
import './ReleaseInfo.scss';
import { StaticQuery, graphql } from "gatsby"

/** Queries the updates markdown and pulls current version and notes. */
function StableReleaseDetail() {
    return (
      <StaticQuery
        query={graphql`
            query {
                allMdx(
                    filter: {
                        frontmatter: { slug: { eq: "updates" } }
                    }
                    sort: { fields: [frontmatter___order], order: ASC }
                ) {
                    edges {
                        node {
                            frontmatter {
                                current_version
                                current_notes
                                current_link
                            }
                        }
                    }
                }
            }
        `}
        render={({ allMdx }) => (
            <div className="c-release-info-latest">
                <b>Latest Stable:</b> {allMdx.edges[0].node.frontmatter.current_version}
                <small>
                    {allMdx.edges[0].node.frontmatter.current_notes} <a href={allMdx.edges[0].node.frontmatter.current_link}>Read More →</a>
                </small>
            </div>
        )}
      />
    )
  }

/**
 * Release info is a block that allows for multiple software release options :-p
 */
const ReleaseInfo = ({ panelId="panelId", title = "title" , children = [] }) => {
    const buttons = React.Children.toArray(children).filter(({ props }) => {
        return props.originalType === 'button';
    });
    const note = React.Children.toArray(children).filter(({ props }) => {
        return props.originalType === 'small';
    });
    return (
        <section id={ panelId } className="c-release-info" title={ title }>
            <div className="c-release-info-inner">
                <div className="c-release-info-version">
                    <StableReleaseDetail />
                </div>
                {
                    buttons.map(({ props: { children, target, href, icon, color, alt = "" } }, index) => (
                        <a
                            target={ target }
                            key={ index }
                            rel="noopener noreferrer"
                            href={ href }
                            style={ color ? { backgroundColor: color } : {} }
                            className="g-button c-release-info-option">
                            <img src={ `/img/icons/${ icon }.svg` } alt={ alt } />
                            { children }
                        </a>
                    ))
                }
                <div className="c-release-info-note">{ note[0].props.children }</div>
            </div>
        </section>
    )
}

export default ReleaseInfo;
