import React from "react";
import "./ProductDescriptor.scss";

/**
 * Product descriptor is used for displaying basic product information.
 */
export default ({
  panelId = "productDescription",
  title = "title",
  image,
  imageAlt = "imageAlt",
  description = "description",
  background = null,
  reverse = false,
  children = [],
}) => {
  const ul = React.Children.toArray(children).filter(({ props }) => {
    return props.originalType === "ul";
  });
  if (background !== null) {
    background = {
      backgroundImage: `url("${background}")`,
    };
  }
  return (
    <section id={panelId} className="c-productdesc" title={title}>
      <h2>{title}</h2>
      <div className="c-productdesc-container" style={background}>
        {!reverse &&
          <>
            <div className="c-productdesc-left">
              <img src={image} alt={imageAlt} />
            </div>
            <div className="c-productdesc-right">
              <div className="c-productdesc-info-box">{description}</div>
              {ul[0].props.children.map(
                ({ props: { children, icon, title } }, index) => (
                  <div
                    key={index}
                    className="c-productdesc-feature"
                    style={{
                      backgroundImage: `url("${icon}")`,
                    }}
                  >
                    <h4>{title}</h4>
                    <p>{children}</p>
                  </div>
                )
              )}
            </div>
          </>
        }

        {reverse && (
          <>
            <div className="c-productdesc-right">
              <div className="c-productdesc-info-box">{description}</div>
              {ul[0].props.children.map(
                ({ props: { children, icon, title } }, index) => (
                  <div
                    key={index}
                    className="c-productdesc-feature"
                    style={{
                      backgroundImage: `url("${icon}")`,
                    }}
                  >
                    <h4>{title}</h4>
                    <p>{children}</p>
                  </div>
                )
              )}
            </div>
            <div className="c-productdesc-left">
              <img src={image} alt={imageAlt} />
            </div>
          </>
        )}
      </div>
    </section>
  );
};
