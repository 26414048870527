
import React from 'react';
import './Hero.scss';

/**
 * Subcontent section that fits into the hero area
 */
const HeroInfo = ({
    title = "title",
    subTitle = "subTitle",
    image = "image",
    children = []
}) => (
    <div className="c-hero-subhero-right-info">
        <img src={ image } alt="We provide" />
        <span>{ title }</span>
        <h2>{ subTitle }</h2>
        <p>{ children }</p>
    </div>
)

/**
 * Large hero component which displays copy below a large text marquee.
 * The information sections below may be disabled with props.
 */
const Hero = ({
    headline = "headline",
    subHeadline = "subHeadline",
    children = [],
    noCapitals = false
}) => (
    <section
        className="c-hero"
        title={ headline }>
        <h1
            className={ `c-hero-headline glitch ${ noCapitals === true ? 'c-hero-headline-nocapital' : '' }` }
            data-text={ headline }>
            { headline }
        </h1>
        <div className="c-hero-subhero">
            <div className="c-index-subhero-left c-hero-info-box">
                { subHeadline }
            </div>
            <div className="c-hero-subhero-right">
                {
                    React.Children.map(
                        children,
                        ({ props: { title, subTitle, image, children } }, index) => (
                            <HeroInfo
                                key={ index }
                                title={ title }
                                subTitle={ subTitle }
                                image={ image }>
                                    { children }
                            </HeroInfo>
                        )
                    )
                }
            </div>
            <div className="c-hero-subhero-right-artifact">
                <img src="/img/icons/snake.svg" alt="Copperhead" />
            </div>
            <figure className="logo">&nbsp;</figure>
        </div>
    </section>
)

export default Hero;
