// Standard corporate information used throughout the site
module.exports = {
    name: "Copperhead",
    short_name: "copperhead",
    corporate_name: "Copperhead Limited",
    corporate_description: "Copperhead is a cyber-security firm located in Toronto, Canada. We specialize in securing Android devices and developing robust security solutions.",
    corporate_phone: "+1 855-684-5569",
    general_email: "info@copperhead.co",
    media_email: "media@copperhead.co",
    description: "Copperhead is a cyber-security firm located in Toronto, Canada. We specialize in securing Android devices and developing robust security solutions.",    brand_colour: "#a2466c",
    background_colour: "#000000",
    url: process.env.GATSBY_ACTIVE_ENV === 'staging' ?
      "https://staging.copperhead.co" :
      "https://copperhead.co",
    google_analytics_id: "UA-137811622-1",
    // Used in footer and JSON+LD tags in header
    address: {
        street: "132 Jarvis Street",
        city: "Toronto",
        region: "Ontario",
        country: "Canada",
        postal: "M5B 2B5"
    },
    // These values propagate through contact, SEO headers and footer of the site
    social: {
        twitter: "copperheadsec",
        facebook: "facebook.com/secureyourandroid",
        youtube: "https://www.youtube.com/channel/UCQMaZY3msrkR8JKOJvqIr2A",
        telegram: "copperheadnews",
        github: "copperheados",
        linkedin: "copperhead-security",
        reddit: "r/Copperhead"
    }
};
