
import React from 'react';
import Helmet from 'react-helmet';
import { SEO } from 'gatsby-theme-seo';
import { MDXProvider } from '@mdx-js/react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import site from '../../config/site';
import './global.scss';

import Hero from '../components/Hero';
import Clients from '../components/Clients';
import FeatureList from '../components/FeatureList';
import ProductDescriptor from '../components/ProductDescriptor';
import PanelContent from '../components/PanelContent';
import ColumnPanel from '../components/ColumnPanel';
import List from '../components/List';
import ReleaseInfo from '../components/ReleaseInfo';
import StructuredList from '../components/StructuredList';

// Declare available shortcodes to expose to MDX
const shortcodes = {
    Hero,
    Clients,
    FeatureList,
    ProductDescriptor,
    PanelContent,
	ColumnPanel,
	List,
	ReleaseInfo,
	StructuredList
};

/**
 * Global template used by all pages. Defines a global structure with CSS
 * dependencies, SEO and other overrides.
 */
const Global = ({
	children,
	page,
	title,
	isBlogPost,
	description = "",
	keywords = "copperheados, secure android phone, copperhead, secure android, mobile security, grapheneos, encrypted phone, hardened android, most secure phone, android os, privacy android, custom rom android, android phone security",
	url = "",
	image = "",
	background = null
}) => {
	if (title === undefined) {
		title = site.name;
	} else {
		title = `${title} - ${site.name}`;
	}
	let fbImage, twImage, shareUrl, descUrl;
	// Override defaults
	if (image === undefined || image === "") {
		fbImage = `${site.url}/img/share/fb_1.jpg`;
		twImage = `${site.url}/img/share/tw_1.jpg`;
	} else {
		fbImage = `${site.url}${image}`;
		twImage = `${site.url}${image}`;
	}
	if (url === "") {
		shareUrl = site.url;
	} else {
		shareUrl = `${site.url}${url}`;
	}
	if (description === "") {
		descUrl = site.corporate_description;
	} else {
		descUrl = description;
	}
	const json_ld = {
		'@context': 'https://schema.org',
		'@type': 'schema:Organization',
		'url': site.url,
		'name': site.corporate_name,
		'email': site.general_email,
		'description': site.corporate_description,
		'logo': site.url + '/img/corporate_logo.png',
		'address': {
			'@type': "PostalAddress",
			'addressLocality': site.address.city,
			'addressRegion': site.address.region,
			'postalCode': site.address.postal,
			'streetAddress': site.address.street
		},
		'provider': [
			{
				'@type': 'Service',
				'serviceType': 'Software'
			},
			{
				'@type': 'Service',
				'serviceType': 'Security'
			},
			{
				'@type': 'Service',
				'serviceType': 'Support',
				'brand': 'Copperhead'
			}
		],
		'contactPoints': [
			{
				'@type': 'ContactPoint',
				'telephone': site.corporate_phone,
				'email': site.general_email,
				'contactType': 'General Inquiries'
			},
			{
				'@type': 'ContactPoint',
				'telephone': site.corporate_phone,
				'email': site.media_email,
				'contactType': 'Media & Press'
			}
		]
	};
	return (
		<>
			<Helmet title={ title } defer={ false }>
				<html lang="en" />
				<SEO title={ title } />
				<script type="application/ld+json">
					{ JSON.stringify(json_ld) }
				</script>
				<meta name="description" content={ descUrl } />
				<meta name="keywords" content={ keywords } />
				{ isBlogPost ? <meta property="og:type" content="article" /> : null }
				{/* <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script> */}
				<meta property="og:url" content={ shareUrl } />
				<meta property="og:title" content={ title } />
				<meta property="og:description" content={ descUrl } />
				<meta property="og:image" content={ fbImage } />
				<meta property="og:author" content="Copperhead" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content={ site.social.twitter } />
				<meta name="twitter:title" content={ title } />
				<meta name="twitter:description" content={ descUrl } />
				<meta name="twitter:image" content={ twImage } />
				<body id={ ! page ? "" : page } />
			</Helmet>
			<Header collapseHeight={ 150 } expandHeight={ 60 } />
			<main style={{
				backgroundImage: background !== null && `url('/img/bg-logo.svg'), url(${background})`
			}}>
				<MDXProvider components={ shortcodes }>
					{ children }
				</MDXProvider>
			</main>
			<Footer />
		</>
	);
}

export default Global;
